<template>
  <div class="upload">
      <h3 class="uploadTitle">Please Upload Monthly bill</h3>
    
    <form>
        <input type="file" id="fileUpload" @change="this.onFileChange" required/>
        <button type="submit" @click="this.onFileSubmit" class="uploadButton">Upload & Download Revised Bill</button>
    </form>
     <h6 v-if="uploading == 'true'">Uploading...</h6>
  </div>
</template>

<script>
import { post } from "axios";
import constants from '../constants';
export default {
  name: "upload",
  data: () => ({
    file: "",
    uploading: ""
  }),
  methods: {
    onFileChange(e) {
      this.file = e.target.files[0];
      console.log("In the file change");
      console.log(this.file)
    },
    fileUpload(file) {
        this.uploading = "true"
      const url = `${constants.backend_url}/fileupload`;
      const formData = new FormData();
      formData.append("calc", file);
      const config = {
        headers: {
          "content-type": "multipart/form-data",
          reponseType: "blob",
        },
      };
      console.log("After");
      return post(url, formData, config);
    },
    onFileSubmit(e) {
      e.preventDefault();
      console.log("in the submit");
      this.fileUpload(this.file).then((response) => {
        if(response.data.status && response.data.status==="failed"){
          alert(response.data.message);
        }else{
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: "application/vnd.ms-excel",
            })
          );
          this.uploading = "false"
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "finalBill.csv");
          document.body.appendChild(link);
          link.click();
        }
      }).catch(err=>{
        if(err.message){
          alert(err.message);
          }
        });
    },
  },
};
</script>

<style>
.upload{
    padding: 40px;
    margin: 10px;
    border: 1px black solid;
}
.uploadTitle{
    margin-bottom: 20px;
}
.uploadButton{
    background-color: rgb(236, 236, 236);
    padding: 10px;
}
</style>